import {FunctionComponent} from 'react';
import {
    ArrayField,
    ChipField,
    Datagrid,
    NumberField,
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    SingleFieldList,
    TextField
} from 'react-admin';
import ClubTitle from '../../components/titles/ClubTitle';

const ClubShowView: FunctionComponent<ShowProps> = (props) => {
    return (
        <Show {...props} title={<ClubTitle/>}>
            <SimpleShowLayout>
                <TextField source={'name'}/>
                <ReferenceField source={'adminId'} reference={'user'}>
                    <TextField source={'email'}/>
                </ReferenceField>
                <ArrayField source={'licenses'}>
                    <Datagrid>
                        <ReferenceField source={'programId'} reference={'program'}>
                            <TextField source={'name'}/>
                        </ReferenceField>
                        <TextField source={'maxInstructors'}/>
                        <ArrayField source={'instructors'}>
                            <SingleFieldList>
                                <ChipField source={'email'}/>
                            </SingleFieldList>
                        </ArrayField>
                        <NumberField source={'startYear'}/>
                        <NumberField source={'startQuarter'}/>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export default ClubShowView;
