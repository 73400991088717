const domainMessages = {
    en: {
        common: {
            'reorder': 'Reorder',
            'reorder-failed': 'Something went wrong',
            'no-file-selected': 'No file selected',
            'file-upload-failed': 'Something went wrong uploading the file, refresh the page and try again',
            'club-import-success': 'The club import was successful, the page will now be refreshed',
            'club-import-failed': 'Something went wrong importing the clubs',
            'outline-import-success': 'The outline import was successful, the page will now be refreshed',
            'outline-import-failed': 'Something went wrong importing the outline'
        },
        resources: {
            club: {
                name: 'Club |||| Clubs',
                fields: {
                    name: 'Name',
                    adminIds: 'Admins',
                    licenses: 'Licenses',
                    programId: 'Program',
                    maxInstructors: 'Max. instructors',
                    instructors: 'Instructors',
                    id: 'Email',
                    startYear: 'From year',
                    startQuarter: 'From quarter'
                }
            },
            program: {
                name: 'Program |||| Programs',
                fields: {
                    name: 'Name',
                    priority: 'Priority',
                    color: 'Color',
                    picture: 'Picture',
                    currentPicture: 'Current picture',
                    pictureHeight: 'Picture height',
                    id: 'Releases'
                }
            },
            release: {
                name: 'Release |||| Releases',
                fields: {
                    'name': 'Name',
                    'programId': 'Program',
                    'year': 'Year',
                    'quarter': 'Quarter',
                    'tracks': 'Tracks',
                    'section': 'Section',
                    'end': 'End',
                    'duration': 'Duration',
                    'title': 'Title',
                    'artist': 'Artist',
                    'start': 'Start',
                    'choreos': 'Choreo',
                    'lyrics': 'Lyrics',
                    'set': 'Set',
                    'exercise': 'Exercise',
                    'sets': 'Sets',
                    'reps': 'Reps',
                    'notes': 'Notes',
                    'picture': 'Picture (575x235)',
                    'currentPicture': 'Current afbeelding',
                    'streamUrlWithoutVoice': 'Stream url unvoiced',
                    'downloadUrlWithoutVoice': 'Download url unvoiced'
                }
            },
            user: {
                name: 'User |||| Users',
                fields: {
                    email: 'E-mail address',
                    password: 'Password',
                    isAdmin: 'Admin',
                    clubsAsAdmin: 'Club admin of',
                    licenses: 'Licenses',
                    id: 'Name'
                }
            },
            news: {
                name: 'News item |||| News items',
                fields: {
                    title: 'Title',
                    url: 'Url',
                    picture: 'Picture (575x235)',
                    currentPicture: 'Current picture'
                }
            },
            event: {
                name: 'Event |||| Events',
                fields: {
                    title: 'Title',
                    url: 'Url',
                    picture: 'Picture (575x235)',
                    currentPicture: 'Current picture'
                }
            }
        }
    }
};

export default domainMessages;
