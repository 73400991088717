import {FunctionComponent} from 'react';
import {Datagrid, Filter, List, ListProps, TextField, TextInput} from 'react-admin';
import {Role} from '../../backend-types';
import DefaultListActions from '../../components/basic/DefaultListActions';

const EventFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <TextInput source={'title'} alwaysOn/>
        </Filter>
    );
};

const EventListView: FunctionComponent<ListProps> = ({permissions, ...props}) => (
    <List {...props} filters={<EventFilter/>} actions={<DefaultListActions isAdmin={permissions === Role.ADMIN}/>}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
        </Datagrid>
    </List>
);

export default EventListView;
