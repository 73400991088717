import {FunctionComponent} from 'react';
import ToReorderButton from '../../basic/ToReorderButton';
import DefaultListActions from '../../basic/DefaultListActions';

const NewsListActions: FunctionComponent<any> = ({isAdmin, ...props}) => {
    return (
        <DefaultListActions {...props}>
            {isAdmin &&
                <ToReorderButton baseRoute={'news'}/>
            }
        </DefaultListActions>
    );
};

export default NewsListActions;
