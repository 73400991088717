import {FunctionComponent} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    Create,
    CreateProps,
    minValue,
    number,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from 'react-admin';

const ClubCreateView: FunctionComponent<CreateProps> = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={'name'} validate={[required()]}/>
                <ReferenceInput source="adminId" reference="user">
                    <SelectInput optionText="email" resettable={true} allowEmpty={true}/>
                </ReferenceInput>
                <ArrayInput source={'licenses'}>
                    <SimpleFormIterator>
                        <ReferenceInput source="programId" reference="program" validate={required()}>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <NumberInput source={'maxInstructors'} validate={[required(), number(), minValue(0)]} defaultValue={3}/>
                        <NumberInput
                            source={'startYear'}
                            validate={[required(), number(), minValue(0)]}
                            defaultValue={new Date().getFullYear()}
                        />
                        <NumberInput source={'startQuarter'} validate={[required(), number(), minValue(0)]} defaultValue={1}/>
                        <ArrayInput source={'instructors'}>
                            <SimpleFormIterator>
                                <ReferenceInput source={'id'} reference={'user'}>
                                    <AutocompleteInput optionText={'email'}/>
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default ClubCreateView;
