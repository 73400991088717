import {FunctionComponent} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    Edit,
    EditProps,
    FormDataConsumer,
    Labeled,
    minValue,
    number,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput
} from 'react-admin';
import {Role} from '../../backend-types';
import ClubTitle from '../../components/titles/ClubTitle';
import EditToolbar from '../../components/toolbars/UserToolbar';

const ClubEditView: FunctionComponent<EditProps> = ({permissions, ...props}) => {
    return (
        <Edit {...props} title={<ClubTitle/>}>
            <SimpleForm toolbar={<EditToolbar isAdmin={permissions === Role.ADMIN}/>}>
                {permissions === Role.ADMIN ?
                    <TextInput source={'name'} validate={[required()]}/>
                    :
                    <TextField source={'name'}/>
                }
                {permissions === Role.ADMIN ?
                    <ReferenceInput source={'adminId'} reference="user">
                        <SelectInput optionText="email" resettable={true} allowEmpty={true}/>
                    </ReferenceInput>
                    :
                    <ReferenceField source={'adminId'} reference={'user'}>
                        <TextField source={'email'}/>
                    </ReferenceField>
                }
                <ArrayInput source={'licenses'}>
                    <SimpleFormIterator
                        disableAdd={permissions !== Role.ADMIN}
                        disableRemove={permissions !== Role.ADMIN}
                    >
                        {permissions === Role.ADMIN ?
                            <ReferenceInput source="programId" reference="program" validate={required()}>
                                <SelectInput optionText="name"/>
                            </ReferenceInput>
                            :
                            <FormDataConsumer>
                                {({getSource, scopedFormData}) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    getSource();
                                    return (
                                        <Labeled label={'Programma'}>
                                            <ReferenceField
                                                source={'programId'}
                                                reference={'program'}
                                                record={scopedFormData}
                                                addLabel={true}
                                            >
                                                <TextField source={'name'} addLabel={true}/>
                                            </ReferenceField>
                                        </Labeled>
                                    );
                                }}
                            </FormDataConsumer>
                        }
                        {permissions === Role.ADMIN ?
                            <NumberInput source={'maxInstructors'} validate={[required(), number(), minValue(0)]} defaultValue={3}/>
                            :
                            <FormDataConsumer>
                                {({getSource, scopedFormData}) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    getSource && getSource();
                                    return (
                                        <Labeled label={'Max. instructeurs'}>
                                            <NumberField
                                                source={'maxInstructors'}
                                                record={scopedFormData}
                                                addLabel={true}
                                            />
                                        </Labeled>
                                    );
                                }}
                            </FormDataConsumer>
                        }
                        {permissions === Role.ADMIN &&
                            <NumberInput
                                source={'startYear'}
                                validate={[required(), number(), minValue(0)]}
                                defaultValue={new Date().getFullYear()}
                            />
                        }
                        {permissions === Role.ADMIN &&
                            <NumberInput source={'startQuarter'} validate={[required(), number(), minValue(0)]} defaultValue={1}/>
                        }
                        <ArrayInput source={'instructors'}>
                            <SimpleFormIterator>
                                <ReferenceInput source={'id'} reference={'user'}>
                                    <AutocompleteInput optionText={'email'}/>
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};

export default ClubEditView;
