import {FunctionComponent} from 'react';
import {Create, CreateProps, ImageField, ImageInput, required, SimpleForm, TextInput} from 'react-admin';

const EventCreateView: FunctionComponent<CreateProps> = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={'title'} validate={required()}/>
                <TextInput source={'url'} validate={required()}/>
                <ImageInput
                    source="pictureObject"
                    label={'resources.news.fields.picture'}
                    accept={['image/png', 'image/jpeg'] as unknown as string}
                    multiple={false}
                >
                    <ImageField source={'src'} title={'title'}/>
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};

export default EventCreateView;
