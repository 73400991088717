export enum Role {
    ADMIN = 'ADMIN',
    CLUB_ADMIN = 'CLUB_ADMIN',
    CLUB_INSTRUCTOR = 'CLUB_INSTRUCTOR'
}

export interface NewsDTO {
    id: string;
    title: string;
    sequence: number;
}

export interface EventDTO {
    id: string;
    title: string;
}

export interface ProgramDTO {
    id: string;
    name: string;
    sequence: number;
    isSpecial: boolean;
}

export interface ClubDTO {
    id: string;
    name: string;
}

export interface ReleaseDTO {
    id: string;
    name: string;
}

export interface UserDTO {
    id: string;
    email: string;
}

export interface UserDetailsDTO {
    id: string;
    email: string;
    isAdmin: boolean;
    clubsAsAdmin?: string[];
    licenseIds?: string[];
}
