import {FunctionComponent} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Edit,
    EditProps,
    PasswordInput,
    ReferenceInput,
    SimpleForm,
    SimpleFormIterator,
    TextField
} from 'react-admin';
import {Role, UserDetailsDTO} from '../../backend-types';
import UserTitle from '../../components/titles/UserTitle';
import EditToolbar from '../../components/toolbars/UserToolbar';
import WithProps from '../../components/basic/WithProps';

const UserEditView: FunctionComponent<EditProps> = ({permissions, ...props}) => {

    return (
        <Edit {...props} title={<UserTitle/>}>
            <WithProps>
                {({record, ...props}: { record: UserDetailsDTO }) => {
                    return (
                        <SimpleForm
                            record={record}
                            {...props}
                            toolbar={<EditToolbar isAdmin={permissions === Role.ADMIN}/>}
                        >
                            <TextField source={'email'}/>
                            {
                                (permissions === Role.ADMIN || (record.clubsAsAdmin && record.clubsAsAdmin?.length <= 0)) &&
                                    <PasswordInput source={'password'}/>
                            }
                            {
                                permissions === Role.ADMIN && <BooleanInput source={'isAdmin'}/>
                            }
                            {
                                permissions === Role.ADMIN &&
                                    <ArrayInput source={'clubsAsAdmin'}>
                                        <SimpleFormIterator>
                                            <ReferenceInput source={'id'} reference={'club'}>
                                                <AutocompleteInput optionText={'name'}/>
                                            </ReferenceInput>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                            }
                            <ArrayInput source={'licenses'}>
                                <SimpleFormIterator>
                                    <ReferenceInput source={'id'} reference={'license'}>
                                        <AutocompleteInput optionText={'name'}/>
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleForm>
                    );
                }}
            </WithProps>
        </Edit>
    );
};

export default UserEditView;
