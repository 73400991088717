import {FunctionComponent} from 'react';
import ToReorderButton from '../../basic/ToReorderButton';
import DefaultListActions from '../../basic/DefaultListActions';

const ProgramListActions: FunctionComponent<any> = ({isAdmin, ...props}) => {
    return (
        <DefaultListActions {...props}>
            {isAdmin &&
                <ToReorderButton baseRoute={'program'}/>
            }
        </DefaultListActions>
    );
};

export default ProgramListActions;
