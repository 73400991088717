import {FunctionComponent} from 'react';
import {sanitizeListRestProps, ShowButton, TopToolbar} from 'react-admin';
import './styles.css';
import ImportOutlineButton from './ImportOutlineButton';

const EditActions: FunctionComponent<any> = ({releaseId, basePath, data, ...props}) => {

    return (
        <TopToolbar {...sanitizeListRestProps(props)}>
            <ImportOutlineButton releaseId={releaseId}/>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );
};

export default EditActions;
