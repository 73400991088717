import {FunctionComponent} from 'react';
import {ImageField, Show, ShowProps, SimpleShowLayout, TextField, TextInput} from 'react-admin';
import NewsTitle from '../../components/titles/NewsTitle';

const EventShowView: FunctionComponent<ShowProps> = (props) => {
    return (
        <Show {...props} title={<NewsTitle/>}>
            <SimpleShowLayout>
                <TextField source={'title'}/>
                <TextInput source={'url'}/>
                <ImageField source={'picture'}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default EventShowView;
