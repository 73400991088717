import {FunctionComponent} from 'react';
import Props from './Props';

const ClubTitle: FunctionComponent<Props> = ({record}) => {
    return (
        <span>{record ? 'Club' + ': ' + record.name : 'Club'}</span>
    );
};

export default ClubTitle;
