import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import {FunctionComponent} from 'react';
import {Admin, Resource} from 'react-admin';
import AuthProvider from './authProvider';
import {Role} from './backend-types';
import DataProvider from './dataProvider';
import ClubCreateView from './entities/club/CreateView';
import ClubEditView from './entities/club/EditView';
import ClubListView from './entities/club/ListView';
import ClubShowView from './entities/club/ShowView';
import NewsCreateView from './entities/news/CreateView';
import NewsEditView from './entities/news/EditView';
import NewsListView from './entities/news/ListView';
import NewsShowView from './entities/news/ShowView';
import ProgramCreateView from './entities/program/CreateView';
import ProgramEditView from './entities/program/EditView';
import ProgramListView from './entities/program/ListView';
import ProgramShowView from './entities/program/ShowView';
import ReleaseCreateView from './entities/release/CreateView';
import ReleaseEditView from './entities/release/EditView';
import ReleaseListView from './entities/release/ListView';
import ReleaseShowView from './entities/release/ShowView';
import UserCreateView from './entities/user/CreateView';
import UserEditView from './entities/user/EditView';
import UserListView from './entities/user/ListView';
import domainMessages from './i18n';
import UserShowView from './entities/user/ShowView';
import clubJoyTheme from './themes/clubJoyTheme';
import EventListView from './entities/event/ListView';
import EventShowView from './entities/event/ShowView';
import EventEditView from './entities/event/EditView';
import EventCreateView from './entities/event/CreateView';
import customRoutes from './customRoutes';

const messages: { [key: string]: any } = {
    en: {...englishMessages, ...domainMessages.en}
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en');

const App: FunctionComponent = () => (
    <Admin
        theme={clubJoyTheme}
        authProvider={AuthProvider}
        dataProvider={DataProvider}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
    >
        {
            permissions => [
                <Resource
                    name={'club'}
                    list={ClubListView}
                    edit={ClubEditView}
                    create={permissions === Role.ADMIN ? ClubCreateView : undefined}
                    show={ClubShowView}
                />,
                <Resource
                    name={'program'}
                    list={ProgramListView}
                    edit={permissions === Role.ADMIN ? ProgramEditView : undefined}
                    create={permissions === Role.ADMIN ? ProgramCreateView : undefined}
                    show={permissions === Role.ADMIN ? ProgramShowView : undefined}
                />,
                <Resource
                    name={'release'}
                    list={ReleaseListView}
                    edit={permissions === Role.ADMIN ? ReleaseEditView : undefined}
                    show={permissions === Role.ADMIN ? ReleaseShowView : undefined}
                    create={permissions === Role.ADMIN ? ReleaseCreateView : undefined}
                />,
                <Resource
                    name={'user'}
                    list={UserListView}
                    show={UserShowView}
                    edit={UserEditView}
                    create={UserCreateView}
                />,
                permissions === Role.ADMIN &&
                <Resource
                    name={'news'}
                    list={NewsListView}
                    show={NewsShowView}
                    edit={NewsEditView}
                    create={NewsCreateView}
                />,
                permissions === Role.ADMIN &&
                <Resource
                    name={'event'}
                    list={EventListView}
                    show={EventShowView}
                    edit={EventEditView}
                    create={EventCreateView}
                />,
                <Resource name={'license'}/>
            ]
        }
    </Admin>
);

export default App;
