import {FunctionComponent} from 'react';
import {
    ArrayInput, BooleanInput,
    Create,
    CreateProps,
    FormTab,
    ImageField,
    ImageInput,
    maxValue,
    minValue,
    number,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';

const ReleaseCreateView: FunctionComponent<CreateProps> = (props) => {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label={'info'}>
                    <ReferenceInput source="programId" reference="program" validate={required()}>
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                    <BooleanInput source={'available'}/>
                    <TextInput source="name" validate={required()}/>
                    <ImageInput
                        source="pictureObject"
                        label={'resources.release.fields.picture'}
                        accept={['image/png', 'image/jpeg'] as unknown as string}
                        multiple={false}
                        validate={required()}
                    >
                        <ImageField source={'src'} title={'title'}/>
                    </ImageInput>
                    <NumberInput source="year" validate={[required(), number(), minValue(0)]}/>
                    <NumberInput source="quarter" validate={[required(), number(), minValue(1), maxValue(4)]}/>
                    <TextInput source="streamUrl" validate={required()}/>
                    <TextInput source="streamUrlWithoutVoice"/>
                    <TextInput source="downloadUrl"/>
                    <TextInput source="downloadUrlWithoutVoice"/>
                </FormTab>
                <FormTab label={'tracks'}>
                    <ArrayInput source={'tracks'}>
                        <SimpleFormIterator>
                            <TextInput source={'title'} validate={required()}/>
                            <TextInput source={'artist'} validate={required()}/>
                            <TextInput source={'section'} validate={required()}/>
                            <NumberInput source={'start'} validate={[required(), number(), minValue(0)]}/>
                            <ArrayInput source={'choreos'}>
                                <SimpleFormIterator>
                                    <NumberInput source={'set'}/>
                                    <NumberInput source={'start'} validate={[required(), number(), minValue(0)]}/>
                                    <TextInput source={'exercise'}/>
                                    <TextInput source={'lyrics'}/>
                                    <TextInput source={'reps'}/>
                                    <TextInput source={'notes'}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default ReleaseCreateView;
