import {FunctionComponent} from 'react';
import {
    BooleanInput,
    Create,
    CreateProps,
    ImageField,
    ImageInput,
    minValue,
    number,
    NumberInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';
import {ColorInput} from 'react-admin-color-input';

const ProgramCreateView: FunctionComponent<CreateProps> = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={'name'} validate={[required()]}/>
                <BooleanInput source={'isSpecial'}/>
                <ColorInput source={'color'} label={'resources.program.fields.color'} validate={required()}/>
                <ImageInput
                    source={'pictureObject'}
                    label={'resources.program.fields.picture'}
                    accept={['image/png', 'image/jpeg'] as unknown as string}
                    multiple={false}
                    validate={required()}
                >
                    <ImageField source={'src'} title={'title'}/>
                </ImageInput>
                <NumberInput source={'pictureHeight'} validate={[number(), minValue(0)]}/>
            </SimpleForm>
        </Create>
    );
};

export default ProgramCreateView;
