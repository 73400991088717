import {ChangeEvent, FunctionComponent, useRef} from 'react';
import {useRedirect, useTranslate} from 'react-admin';
import {accessTokenKey} from '../../../../authProvider';
import ImportExport from '@material-ui/icons/ImportExport';
import './styles.css';

const ImportClubsButton: FunctionComponent = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const redirect = useRedirect();
    const translate = useTranslate();

    const uploadSheet = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const file: File | undefined = event.target.files?.[0];
        const accessToken: string | null = localStorage.getItem(accessTokenKey);

        if (!file) {
            // eslint-disable-next-line no-alert
            alert(translate('common.no-file-selected'));
            return;
        }

        const formData = new FormData();

        formData.append('file', file, file.name);

        fetch(process.env.REACT_APP_API_URL + '/club/import', {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(async (response) => {
            if (response.ok) {
                // eslint-disable-next-line no-alert
                alert(translate('common.club-import-success'));
                redirect('/');
            } else {
                const responseJson = await response.json();

                // eslint-disable-next-line no-alert
                alert(translate('common.club-import-failed') + ': ' + responseJson.details + ' (' + responseJson.message + ')');
                const input = inputRef.current;
                if (input) {
                    input.value = '';
                }
            }
        });
    };

    return (
        <div className="import-wrapper MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall">
            <label className="styled-file-input">
                <input
                    className="file-input"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={uploadSheet}
                    ref={inputRef}
                />
                <ImportExport/>
                <span>Clubs importeren</span>
            </label>
        </div>
    );
};

export default ImportClubsButton;
