import {FunctionComponent} from 'react';
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    Filter,
    List,
    ListProps,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import {Role} from '../../backend-types';
import DefaultListActions from '../../components/basic/DefaultListActions';

const ReleaseFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <TextInput source={'name'} alwaysOn/>
            <ReferenceInput source={'programId'} reference="program">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <NumberInput source={'year'}/>
            <NumberInput source={'quarter'}/>
            <BooleanInput source={'available'}/>
        </Filter>
    );
};

const ReleaseListView: FunctionComponent<ListProps> = ({permissions, ...props}) => {
    return (
        <List
            filters={<ReleaseFilter/>}
            bulkActionButtons={false}
            actions={<DefaultListActions isAdmin={permissions === Role.ADMIN}/>}
            {...props}
        >
            <Datagrid rowClick={'show'}>
                <TextField source="name"/>
                <ReferenceField source={'programId'} reference="program">
                    <TextField source="name"/>
                </ReferenceField>
                <NumberField source="year"/>
                <NumberField source="quarter"/>
                <BooleanField source={'available'}/>
            </Datagrid>
        </List>
    );
};

export default ReleaseListView;
