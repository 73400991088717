import {FunctionComponent} from 'react';
import {BooleanField, Datagrid, Filter, List, ListProps, TextField, TextInput} from 'react-admin';
import {Role} from '../../backend-types';
import ProgramListActions from '../../components/toolbars/ProgramListActions';

const ProgramFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <TextInput source={'name'} alwaysOn/>
        </Filter>
    );
};

const ProgramListView: FunctionComponent<ListProps> = ({permissions, ...props}) => {
    return (
        <List
            filters={<ProgramFilter/>}
            bulkActionButtons={false}
            actions={<ProgramListActions isAdmin={permissions === Role.ADMIN}/>}
            sort={{field: 'sequence', order: 'ASC'}}
            perPage={25}
            {...props}
        >
            <Datagrid rowClick={'show'}>
                <TextField source={'name'}/>
                {permissions === Role.ADMIN && <BooleanField source={'isSpecial'}/>}
            </Datagrid>
        </List>
    );
};

export default ProgramListView;
