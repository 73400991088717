import {FunctionComponent} from 'react';
import DefaultListActions from '../../basic/DefaultListActions';
import ImportClubsButton from './ImportClubsButton';
import ExportClubsButton from './ExportClubsButton';

const ClubListActions: FunctionComponent<any> = ({isAdmin, ...props}) => {

    return (
        <DefaultListActions {...props}>
            {isAdmin && <>
                <ImportClubsButton/>
                <ExportClubsButton/>
            </>}
        </DefaultListActions>
    );
};

export default ClubListActions;
