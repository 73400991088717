import {fetchUtils} from 'ra-core';
import {accessTokenKey} from './authProvider';
import {HttpError} from 'react-admin';

export const httpClient = (url: string, options?: fetchUtils.Options): Promise<{ status: number; headers: Headers; body: string; json: any }> => {
    let responseOptions;
    if (options) {
        responseOptions = options;
    } else {
        responseOptions = {};
    }

    if (!responseOptions.headers) {
        responseOptions.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem(accessTokenKey);
    const headers = responseOptions.headers as Headers;
    headers.set('Authorization', `Bearer ${token}`);
    return fetchJson(url, responseOptions);
};

// Custom implementation of fetchUtils.fetchJson so we can overwrite the error messages
const fetchJson = async (url: string, options: { [key: string]: any } = {}) => {
    const requestHeaders = (options.headers ||
        new Headers({
            Accept: 'application/json'
        })
    );
    if (!requestHeaders.has('Content-Type') &&
        !(options && options.body && options.body instanceof FormData)) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }
    const response = await fetch(url, {...options, headers: requestHeaders});
    const text = await response.text();
    const o = {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text
    };
    const status = o.status;
    const statusText = o.statusText;
    const headers = o.headers;
    const body = o.body;

    let json;
    try {
        json = JSON.parse(body);
    } catch (e) {
        // not json, no big deal
    }
    if (status < 200 || status >= 300) {
        // Add error details to API error messages
        return Promise.reject(new HttpError((json && (json.message && json.details) && json.message + ': ' + json.details) || statusText, status, json));
    }
    return Promise.resolve({status: status, headers: headers, body: body, json: json});
};
