import {FunctionComponent} from 'react';
import {accessTokenKey} from '../../../../authProvider';
import ImportExport from '@material-ui/icons/ImportExport';
import dayjs from 'dayjs';
import './styles.css';

const ExportClubsButton: FunctionComponent = () => {

    const downloadExport = async (): Promise<void> => {
        const accessToken: string | null = localStorage.getItem(accessTokenKey);

        const response = await fetch(process.env.REACT_APP_API_URL + '/club/export', {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        });

        if (response.ok) {
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;

            const timestamp: string = dayjs().format('DD-MM-YYYY-hh:mm:ss');

            a.download = `club-export_${timestamp}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(blobUrl);
        }
    };

    return (
        <div
            className="export-button MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
            onClick={downloadExport}
        >
            <ImportExport/>
            <span>Clubs exporteren</span>
        </div>
    );
};

export default ExportClubsButton;
