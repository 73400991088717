import {FunctionComponent} from 'react';
import {
    BooleanField,
    ChipField,
    ImageField,
    NumberField,
    ReferenceManyField,
    Show,
    ShowProps,
    SimpleShowLayout,
    SingleFieldList,
    TextField
} from 'react-admin';
import ProgramTitle from '../../components/titles/ProgramTitle';
import {ColorField} from 'react-admin-color-input';

const ProgramShowView: FunctionComponent<ShowProps> = (props) => {
    return (
        <Show {...props} title={<ProgramTitle/>}>
            <SimpleShowLayout>
                <TextField source={'name'}/>
                <BooleanField source={'isSpecial'}/>
                <ColorField source={'color'}/>
                <ImageField source={'picture'}/>
                <NumberField source={'pictureHeight'}/>
                <ReferenceManyField reference={'release'} target={'programId'}>
                    <SingleFieldList>
                        <ChipField source={'name'}/>
                    </SingleFieldList>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    );
};

export default ProgramShowView;
