import {cloneElement, FunctionComponent} from 'react';
import {CreateButton, sanitizeListRestProps, TopToolbar} from 'react-admin';

const DefaultListActions: FunctionComponent<any> = ({filters, isAdmin = true, children, ...props}) => {

    return (
        <TopToolbar {...sanitizeListRestProps(props)}>
            {cloneElement(filters, {context: 'button'})}
            {children}
            {isAdmin && <CreateButton/>}
        </TopToolbar>
    );
};

export default DefaultListActions;
