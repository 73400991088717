import {FunctionComponent} from 'react';
import {DeleteButton, SaveButton, Toolbar} from 'react-admin';

const EditToolbar: FunctionComponent<any> = ({isAdmin, ...props}) => (
    <Toolbar {...props} >
        <SaveButton disabled={props.pristine}/>
        {isAdmin && <DeleteButton style={{marginLeft: 'auto'}}/>}
    </Toolbar>
);

export default EditToolbar;
