import {FunctionComponent} from 'react';
import {Edit, EditProps, ImageField, ImageInput, required, SimpleForm, TextInput} from 'react-admin';
import NewsTitle from '../../components/titles/NewsTitle';

const NewsEditView: FunctionComponent<EditProps> = (props) => {
    return (
        <Edit {...props} title={<NewsTitle/>}>
            <SimpleForm>
                <TextInput source={'title'} validate={required()}/>
                <TextInput source={'url'} validate={required()}/>
                <ImageField source={'picture'} label={'resources.news.fields.currentPicture'}/>
                <ImageInput
                    source="pictureObject"
                    label={'resources.news.fields.picture'}
                    accept={['image/png', 'image/jpeg'] as unknown as string}
                    multiple={false}
                >
                    <ImageField source={'src'} title={'title'}/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};

export default NewsEditView;
