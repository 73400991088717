import {createMuiTheme} from 'react-admin';

const clubJoyTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#F50D45'
        },
        secondary: {
            main: '#05003D'
        },
        error: {
            main: '#EF3333'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2
    }
});

export default clubJoyTheme;
