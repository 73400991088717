import {FunctionComponent} from 'react';
import {
    ArrayField,
    BooleanField,
    Datagrid,
    ImageField,
    NumberField,
    ReferenceField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import ReleaseTitle from '../../components/titles/ReleaseTitle';

const ReleaseShowView: FunctionComponent<ShowProps> = (props) => {
    return (
        <Show {...props} title={<ReleaseTitle/>}>
            <TabbedShowLayout>
                <Tab label={'info'}>
                    <ReferenceField source={'programId'} reference="program">
                        <TextField source="name"/>
                    </ReferenceField>
                    <BooleanField source={'available'}/>
                    <TextField source="name"/>
                    <ImageField source={'picture'}/>
                    <NumberField source="year"/>
                    <NumberField source="quarter"/>
                    <TextField source="streamUrl"/>
                    <TextField source="streamUrlWithoutVoice"/>
                    <TextField source="downloadUrl"/>
                    <TextField source="downloadUrlWithoutVoice"/>
                </Tab>
                <Tab label={'tracks'}>
                    <ArrayField source="tracks">
                        <Datagrid
                            isRowExpandable={(record) => record.choreos?.length > 0}
                            expand={
                                <ArrayField source="choreos">
                                    <Datagrid>
                                        <NumberField source={'set'}/>
                                        <NumberField source={'start'}/>
                                        <TextField source={'lyrics'}/>
                                        <TextField source={'exercise'}/>
                                        <TextField source={'reps'}/>
                                        <TextField source={'notes'}/>
                                    </Datagrid>
                                </ArrayField>
                            }>
                            <TextField source={'section'}/>
                            <TextField source={'title'}/>
                            <TextField source={'artist'}/>
                            <NumberField source={'start'}/>
                            <NumberField source={'end'}/>
                            <NumberField source={'duration'}/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default ReleaseShowView;
