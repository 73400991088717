import {FunctionComponent} from 'react';
import Props from './Props';

const NewsTitle: FunctionComponent<Props> = ({record}) => {
    return (
        <span>{record ? 'Nieuws item' + ': ' + record.title : 'Nieuws item'}</span>
    );
};

export default NewsTitle;
