import {FunctionComponent} from 'react';
import Props from './Props';

const ProgramTitle: FunctionComponent<Props> = ({record}) => {
    return (
        <span>{record ? 'Programma' + ': ' + record.name : 'Programma'}</span>
    );
};

export default ProgramTitle;
