import {FunctionComponent} from 'react';
import {BooleanField, Datagrid, Filter, List, ListProps, TextField, TextInput} from 'react-admin';
import {Role} from '../../backend-types';
import DefaultListActions from '../../components/basic/DefaultListActions';

const UserFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <TextInput source={'email'} alwaysOn/>
        </Filter>
    );
};

const UserListView: FunctionComponent<ListProps> = ({permissions, ...props}) => {
    return (
        <List {...props} filters={<UserFilter/>} bulkActionButtons={false} actions={<DefaultListActions isAdmin={true}/>}>
            <Datagrid rowClick={'show'}>
                <TextField source={'email'}/>
                {permissions === Role.ADMIN && <BooleanField source={'isAdmin'}/>}
            </Datagrid>
        </List>
    );
};

export default UserListView;
