import {FunctionComponent} from 'react';
import {Datagrid, Filter, List, ListProps, TextField, TextInput} from 'react-admin';
import {Role} from '../../backend-types';
import NewsListActions from '../../components/toolbars/NewsListActions';

const NewsFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <TextInput source={'title'} alwaysOn/>
        </Filter>
    );
};

const NewsListView: FunctionComponent<ListProps> = ({permissions, ...props}) => {
    return (
        <List
            filters={<NewsFilter/>}
            actions={<NewsListActions isAdmin={permissions === Role.ADMIN}/>}
            sort={{field: 'sequence', order: 'ASC'}}
            {...props}
        >
            <Datagrid rowClick="edit">
                <TextField source="title"/>
            </Datagrid>
        </List>
    );
};

export default NewsListView;
