import {FunctionComponent} from 'react';
import {Datagrid, Filter, List, ListProps, TextField, TextInput} from 'react-admin';
import {Role} from '../../backend-types';
import ClubListActions from '../../components/toolbars/ClubListActions';

const ClubFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <TextInput source={'name'} alwaysOn/>
        </Filter>
    );
};

const ClubListView: FunctionComponent<ListProps> = ({permissions, ...props}) => {
    return (
        <List
            {...props}
            filters={<ClubFilter/>}
            bulkActionButtons={false}
            actions={<ClubListActions isAdmin={permissions === Role.ADMIN}/>}
        >
            <Datagrid rowClick={'show'}>
                <TextField source={'name'}/>
            </Datagrid>
        </List>
    );
};

export default ClubListView;
