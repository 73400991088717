import {FunctionComponent} from 'react';
import Props from './Props';

const ReleaseTitle: FunctionComponent<Props> = ({record}) => {
    return (
        <span>{record ? 'Release' + ': ' + record.name : 'Release'}</span>
    );
};

export default ReleaseTitle;
