import {PropsWithChildren, useEffect, useState} from 'react';
import Props from './Props';
import {Title, useRedirect, useTranslate} from 'react-admin';
import {httpClient} from '../../../httpClient';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Save from '@material-ui/icons/Save';
import './styles.css';

const ReorderTable = <T extends { id: string; sequence: number; }, >(
    {
        basePath,
        renderItem,
        jsonKey,
        title
    }: PropsWithChildren<Props<T>>) => {
    const [records, setRecords] = useState<T[]>([]);
    const redirect = useRedirect();
    const translate = useTranslate();

    useEffect(() => {
        fetchRecords();
    }, []);

    const fetchRecords = async () => {
        const {json} = await httpClient(`${process.env.REACT_APP_API_URL}/${basePath}`);
        setRecords(json ?? []);
    };

    const onDragEnd = (result: DropResult) => {
        const {source, destination} = result;

        if (source.index >= 0 && source.index < records.length && destination) {
            const element = records[source.index] as T;

            const updatedRecords = [...records];
            updatedRecords.splice(source.index, 1);
            updatedRecords.splice(destination?.index, 0, element);

            setRecords(updatedRecords);
        }
    };

    const submitRecords = async () => {
        httpClient(`${process.env.REACT_APP_API_URL}/${basePath}/reorder`, {
            method: 'POST',
            body: JSON.stringify({
                [jsonKey]: records.map((record, index) => ({
                    id: record.id,
                    sequence: index
                }))
            })
        }).then(() => {
            redirect(`/${basePath}`);
        }).catch((e) => {
            // eslint-disable-next-line no-alert
            alert(translate('common.reorder-failed') + ' (' + e.message + ')');
        });
    };

    return (
        <Card className={'reorder'}>
            <Title title={`${title}: Volgorde aanpassen`}/>
            <CardContent>
                <DragDropContext onDragEnd={onDragEnd}>
                    <TableContainer>
                        <Table aria-label={`${basePath} list`}>
                            <Droppable droppableId={`droppable-${basePath}`} type={basePath.toUpperCase()}>
                                {(provided) => (
                                    <TableBody
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {records.map((record, index) => {
                                            return (
                                                <Draggable
                                                    key={record.id}
                                                    draggableId={record.id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={index}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >
                                                            <TableCell {...provided.dragHandleProps} align="left">
                                                                <DragHandleIcon/>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <span>{index + 1}</span>
                                                            </TableCell>
                                                            {renderItem(record)}
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </Table>
                    </TableContainer>
                </DragDropContext>
                <button
                    className={'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary reorder-save-button'}
                    onClick={submitRecords}
                >
                    <Save/>
                    <span className={'MuiButton-label'}>{translate('ra.action.save')}</span>
                </button>
            </CardContent>
        </Card>
    );
};

export default ReorderTable;
