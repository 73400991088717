import {FunctionComponent} from 'react';
import Props from './Props';

const UserTitle: FunctionComponent<Props> = ({record}) => {
    return (
        <span>{record ? 'Gebruiker' + ': ' + record.email : 'Gebruiker'}</span>
    );
};

export default UserTitle;
