import {ChangeEvent, FunctionComponent} from 'react';
import {useRedirect, useTranslate} from 'react-admin';
import {accessTokenKey} from '../../../../authProvider';
import ImportExport from '@material-ui/icons/ImportExport';

const ImportOutlineButton: FunctionComponent<{ releaseId: string }> = ({releaseId}) => {
    const redirect = useRedirect();
    const translate = useTranslate();

    const uploadOutline = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const file: File | undefined = event.target.files?.[0];
        const accessToken: string | null = localStorage.getItem(accessTokenKey);

        if (!file || !releaseId) {
            // eslint-disable-next-line no-alert
            alert(!file ? translate('common.no-file-selected') : translate('common.file-upload-failed'));
            return;
        }

        const formData = new FormData();

        formData.append('releaseId', releaseId);
        formData.append('file', file, file.name);

        fetch(process.env.REACT_APP_API_URL + '/release/importOutline', {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        }).then(async (response) => {
            if (response.ok) {
                // eslint-disable-next-line no-alert
                alert(translate('common.outline-import-success'));
                redirect(`${releaseId}/show/1`);
            } else {
                const responseJson = await response.json();

                // eslint-disable-next-line no-alert
                alert(translate('common.outline-import-failed') + ': ' + responseJson.details + ' (' + responseJson.message + ')');
            }
        });
    };

    return (
        <div className="import-wrapper MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall">
            <label className="styled-file-input">
                <input
                    className="file-input"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={uploadOutline}
                />
                <ImportExport/>
                <span>Outline importeren</span>
            </label>
        </div>
    );
};

export default ImportOutlineButton;
