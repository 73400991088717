import {FunctionComponent} from 'react';
import {NewsDTO} from '../../../backend-types';
import {TableCell} from '@material-ui/core';
import ReorderTable from '../../../components/basic/ReorderTable';

const NewsReorderView: FunctionComponent = () => {
    return (
        <ReorderTable
            title={'Nieuws items'}
            jsonKey={'news'}
            basePath={'news'}
            items={[] as NewsDTO[]}
            renderItem={(record) => (
                <TableCell align="left">
                    <span>{record.title}</span>
                </TableCell>
            )}
        />
    );
};

export default NewsReorderView;
