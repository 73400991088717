import {FunctionComponent} from 'react';
import {
    ArrayInput,
    BooleanInput,
    Edit,
    EditProps,
    FormTab,
    ImageField,
    ImageInput,
    maxValue,
    minValue,
    number,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import ReleaseEditActions from '../../components/toolbars/ReleaseEditActions';
import ReleaseTitle from '../../components/titles/ReleaseTitle';

const ReleaseEditView: FunctionComponent<EditProps> = (props) => {
    return (
        <Edit
            title={<ReleaseTitle/>}
            actions={<ReleaseEditActions releaseId={props.id}/>}
            {...props}
        >
            <TabbedForm>
                <FormTab label={'info'}>
                    <ReferenceInput source="programId" reference="program" validate={required()}>
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                    <BooleanInput source={'available'}/>
                    <TextInput source="name" validate={required()}/>
                    <ImageField source={'picture'} label={'resources.release.fields.currentPicture'}/>
                    <ImageInput
                        source="pictureObject"
                        label={'resources.release.fields.picture'}
                        accept={['image/png', 'image/jpeg'] as unknown as string}
                        multiple={false}
                    >
                        <ImageField source={'src'} title={'title'}/>
                    </ImageInput>
                    <NumberInput source="year" validate={[required(), number(), minValue(0)]}/>
                    <NumberInput source="quarter" validate={[required(), number(), minValue(1), maxValue(4)]}/>
                    <TextInput source="streamUrl" validate={required()}/>
                    <TextInput source="streamUrlWithoutVoice"/>
                    <TextInput source="downloadUrl"/>
                    <TextInput source="downloadUrlWithoutVoice"/>
                </FormTab>
                <FormTab label={'tracks'}>
                    <ArrayInput source={'tracks'}>
                        <SimpleFormIterator>
                            <TextInput source={'title'} validate={required()}/>
                            <TextInput source={'artist'} validate={required()}/>
                            <TextInput source={'section'} validate={required()}/>
                            <NumberInput source={'start'} validate={[required(), number(), minValue(0)]}/>
                            <NumberInput source={'end'} validate={[required(), number(), minValue(0)]}/>
                            <NumberInput source={'duration'} disabled/>
                            <ArrayInput source={'choreos'}>
                                <SimpleFormIterator>
                                    <NumberInput source={'set'}/>
                                    <NumberInput source={'start'} validate={[required(), number(), minValue(0)]}/>
                                    <TextInput source={'exercise'}/>
                                    <TextInput source={'lyrics'}/>
                                    <TextInput source={'reps'}/>
                                    <TextInput source={'notes'}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default ReleaseEditView;
