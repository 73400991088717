import {FunctionComponent} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Create,
    CreateProps,
    PasswordInput,
    ReferenceInput,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from 'react-admin';
import {Role} from '../../backend-types';

const UserCreateView: FunctionComponent<CreateProps> = ({permissions, ...props}) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={'email'} validate={required()}/>
                <PasswordInput source={'password'} validate={required()}/>
                {permissions === Role.ADMIN && <BooleanInput source={'isAdmin'}/>}
                {
                    permissions === Role.ADMIN &&
                    <ArrayInput source={'clubsAsAdmin'}>
                        <SimpleFormIterator>
                            <ReferenceInput source={'id'} reference={'club'}>
                                <AutocompleteInput optionText={'name'}/>
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                }
                <ArrayInput source={'licenses'} validate={permissions === Role.ADMIN ? undefined : required()}>
                    <SimpleFormIterator>
                        <ReferenceInput source={'id'} reference={'license'}>
                            <AutocompleteInput optionText={'name'}/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default UserCreateView;
