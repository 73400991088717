import {FunctionComponent} from 'react';
import {ProgramDTO} from '../../../backend-types';
import {TableCell} from '@material-ui/core';
import ReorderTable from '../../../components/basic/ReorderTable';

const ProgramReorderView: FunctionComponent = () => {
    return (
        <ReorderTable
            title={'Programma\'s'}
            jsonKey={'programs'}
            basePath={'program'}
            items={[] as ProgramDTO[]}
            renderItem={(record) => (
                <>
                    <TableCell align="left">
                        <span>{record.name}</span>
                    </TableCell>
                    <TableCell align="right">
                        <span>{record.isSpecial ? 'Special' : 'Essential'}</span>
                    </TableCell>
                </>
            )}
        />
    );
};

export default ProgramReorderView;
