import {FunctionComponent} from 'react';
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    EditProps,
    ReferenceField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField
} from 'react-admin';
import {Role} from '../../backend-types';
import UserTitle from '../../components/titles/UserTitle';

const UserShowView: FunctionComponent<EditProps> = ({permissions, ...props}) => {
    return (
        <Show {...props} title={<UserTitle/>}>
            <SimpleShowLayout>
                <TextField source={'email'}/>
                {permissions === Role.ADMIN && <BooleanField source={'isAdmin'}/>}
                {permissions === Role.ADMIN &&
                    <ArrayField source={'clubsAsAdmin'}>
                        <SingleFieldList>
                            <ChipField source={'name'}/>
                        </SingleFieldList>
                    </ArrayField>
                }
                <ArrayField source={'licenses'}>
                    <Datagrid>
                        <ReferenceField source={'clubId'} reference={'club'}>
                            <TextField source={'name'}/>
                        </ReferenceField>
                        <ReferenceField source={'programId'} reference={'program'}>
                            <TextField source={'name'}/>
                        </ReferenceField>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export default UserShowView;
