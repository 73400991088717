import {FunctionComponent} from 'react';
import {useRedirect, useTranslate} from 'react-admin';
import './styles.css';

const ToReorderButton: FunctionComponent<{baseRoute: string}> = ({baseRoute}) => {
    const translate = useTranslate();
    const redirect = useRedirect();

    return (
        <div
            className="reorder-button MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
            onClick={() => redirect(baseRoute + '/reorder')}
        >
            <span>{translate('common.reorder')}</span>
        </div>
    );
};

export default ToReorderButton;
